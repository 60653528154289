export const seekVideo = (position) => {
  const player = window.streamfizzPlayer;
  if (!player) return;
  player.currentTime(position);
};

export const playVideo = () => {
  const player = window.streamfizzPlayer;
  if (!player) return;
  player.play();
};

export const getPlayerState = () => {
  const player = window.streamfizzPlayer;
  if (!player) return;
  if (player.paused()) return 'paused';
  if (player.ended()) return 'ended';
  if (player.currentTime() > 0 && player.readyState() > 2) return 'playing';
};

const getCurrentVideoIndex = (playlist) => {
  if (typeof window === 'undefined') return;

  const player = window.streamfizzPlayer;
  if (!player) return;
  // get the current playlist Content Id
  const currentVideoId = player.currentSource()?.playlistContentId;
  // find the index of the current playlist Content playing
  const currentVideoIndex = playlist.content.findIndex(
    (item) => item.id === currentVideoId
  );
  return currentVideoIndex;
};

export const getNextVideo = (playlist) => {
  const currentIndex = getCurrentVideoIndex(playlist);
  // get the next video
  return playlist.content[currentIndex + 1];
};

export const getPreviousVideo = (playlist) => {
  const currentIndex = getCurrentVideoIndex(playlist);
  // get the next video
  return playlist.content[currentIndex - 1];
};

export const getCurrentItemFromPlaylist = (
  playlist,
  currentPlaylistContentId
) => {
  const item = playlist.content.find(
    (item) => item.id === currentPlaylistContentId
  );
  if (!item) return;
  return Object.assign({ language: item?.language }, item[item.type]);
};

export const getPosition = () => {
  const player = window.streamfizzPlayer;
  if (!player) return;
  return player.currentTime();
};

export const resetPlayer = (src) => {
  try {
    const player = window.streamfizzPlayer;
    if (!player) return;
    player.pause();
    const playButton = document.querySelector('.vjs-big-play-button');
    if (playButton) playButton.style.opacity = 0;
    player.changeSrc(src);
    setTimeout(() => {
      if (playButton) playButton.click();
      if (playButton) playButton.style.opacity = '';
      console.log('player has been reseted', src);
    }, 200);
  } catch (error) {
    console.log('resetPlayer error', error);
  }
};

export const isVideoPlaying = () => {
  const player = window.streamfizzPlayer;
  if (!player) return;
  return !!(
    player.currentTime() > 0 &&
    !player.paused() &&
    !player.ended() &&
    player.readyState() > 2
  );
};

export const startTracking = () => {
  const player = window.streamfizzPlayer;
  if (!player) return;
  player.liveTracker.startTracking();
  player.addClass('vjs-liveui');
};

export const getCurrentResolution = () => {
  const player = window.streamfizzPlayer;
  if (!player) return;
  const qualityList = player.qualityLevels();
  /* eslint-disable no-underscore-dangle */
  const currentResolution =
    qualityList?.levels_[qualityList.selectedIndex]?.height;
  /* eslint-enable no-underscore-dangle */
  return currentResolution ? `${currentResolution.toString()}p` : '';
};

// input 02:04:33 => output => 7473
export const HourMinuteSecondToSecond = (hms = '00:00:00') => {
  // [02, 04, 33]
  const value = hms.split(':');
  return parseInt(+value[0] * 60 * 60 + +value[1] * 60 + +value[2], 10);
};

/* eslint-disable prefer-rest-params */
export const addPlaylistButton = (videojs) => {
  const Button = videojs.getComponent('Button');
  // Extend default
  const PrevButton = videojs.extend(Button, {
    // constructor: function(player, options) {
    constructor() {
      Button.apply(this, arguments);
      this.addClass('icon-angle-left');
      this.addClass('playlist-prev');
      this.controlText('Previous');
    },

    handleClick() {
      window.streamfizzPlayer.trigger('playPreviousVideo');
    }
  });

  /* ADD BUTTON */
  const NextButton = videojs.extend(Button, {
    constructor() {
      Button.apply(this, arguments);
      this.addClass('icon-angle-right');
      this.addClass('playlist-next');
      this.controlText('Next');
    },

    handleClick() {
      window.streamfizzPlayer.trigger('playNextVideo');
    }
  });

  // const RepeatButton = videojs.extend(Button, {
  //   constructor() {
  //     Button.apply(this, arguments);
  //     this.addClass('icon-repeat');
  //     this.controlText('Repeat');
  //   },

  //   handleClick() {
  //     // setIsFirstPlay(true);
  //     // window.streamfizzPlayer.playlist.next();
  //   }
  // });

  // Register the new component
  videojs.registerComponent('NextButton', NextButton);
  videojs.registerComponent('PrevButton', PrevButton);
  // videojs.registerComponent('RepeatButton', RepeatButton);
  window.streamfizzPlayer.getChild('controlBar').addChild('PrevButton', {}, 0);
  window.streamfizzPlayer.getChild('controlBar').addChild('NextButton', {}, 2);
};
/* eslint-enable prefer-rest-params */
