import { GET_DISCUSSION_PROPERTIES } from '../queries/discussion';
import { GET_LIVE_DATA } from '../queries/live';
import { GET_MEDIA_DATA } from '../queries/media';
import { GET_PLAYLIST } from '../queries/playlist';
import { getCurrentItemFromPlaylist } from './player';

export const getContentParams = (mediaId, liveId, playlistId, discussionId) => {
  if (mediaId)
    return {
      query: GET_MEDIA_DATA,
      contentId: mediaId,
      contentType: 'media'
    };
  if (liveId)
    return {
      query: GET_LIVE_DATA,
      contentId: liveId,
      contentType: 'live'
    };
  if (playlistId)
    return {
      query: GET_PLAYLIST,
      contentId: playlistId,
      contentType: 'playlist'
    };
  if (discussionId)
    return {
      query: GET_DISCUSSION_PROPERTIES,
      contentId: discussionId,
      contentType: 'discussion'
    };
  return {
    query: GET_MEDIA_DATA,
    contentId: null,
    contentType: null
  };
};

export const getContent = (media, live, playlist) => {
  if (!media && !live && !playlist) return {};
  const isMedia = false;
  const isLive = false;
  const isPlaylist = false;

  if (media)
    return {
      content: media,
      contentType: 'media',
      contentId: media.id,
      isMedia: true,
      isLive,
      isPlaylist
    };
  if (live)
    return {
      content: live,
      contentType: 'live',
      contentId: live.id,
      isMedia,
      isLive: true,
      isPlaylist
    };
  if (playlist) {
    return {
      content: playlist,
      contentType: 'playlist',
      contentId: playlist.id,
      isMedia,
      isLive,
      isPlaylist: true
    };
  }
};

export const getHeadInfos = (content, isLive) => ({
  title:
    content?.pageSetting?.pageTitle ||
    content?.pageSetting?.videoTitle ||
    'Streamfizz Player',
  description: content.pageSetting?.pageText || '',
  image: isLive ? content?.waitingThumb : content?.selectedThumb
});

export const getBrandSetting = (content, isEmbed) =>
  isEmbed ? content?.embedSetting : content?.pageSetting;

const getFiles = (content, contentType, currentPlaylistContentId) => {
  const files = (
    contentType === 'playlist'
      ? getCurrentItemFromPlaylist(content, currentPlaylistContentId)
      : content
  )?.files;
  if (!files) return [];
  // filter to get only the active pdf files
  const activeFiles = files.filter(
    (file) => file.isActive && file.type === 'pdf'
  );
  return activeFiles;
};

const getTranscripts = (content, contentType, currentPlaylistContentId) => {
  const subtitles = (
    contentType === 'playlist'
      ? getCurrentItemFromPlaylist(content, currentPlaylistContentId)
      : content
  )?.subtitles;
  return subtitles || [];
};

const getChapters = (content, contentType, currentPlaylistContentId) => {
  const chapters = (
    contentType === 'playlist'
      ? getCurrentItemFromPlaylist(content, currentPlaylistContentId)
      : content
  )?.chapters;
  return chapters || [];
};

export const handleSideBar = (
  content,
  contentType,
  brandSetting,
  currentPlaylistContentId
) => {
  // get files
  const files = getFiles(content, contentType, currentPlaylistContentId);

  // get transcripts
  const shouldShowTranscript = brandSetting?.shouldShowTranscript;
  const transcripts = shouldShowTranscript
    ? getTranscripts(content, contentType, currentPlaylistContentId)
    : [];

  // get chapters
  const shouldShowChapter = brandSetting?.shouldShowChapter;
  const chapters = shouldShowChapter
    ? getChapters(content, contentType, currentPlaylistContentId)
    : [];

  // get discussion
  const shouldShowDiscussion = brandSetting?.shouldShowDiscussion;

  // based on above, let's define if the sidebar has any content to display
  const sidebarHasContent =
    !!(
      files.length ||
      (shouldShowTranscript && transcripts.length) ||
      (shouldShowChapter && chapters.length) ||
      shouldShowDiscussion ||
      contentType === 'playlist'
    ) &&
    typeof window !== 'undefined' &&
    !window?.location?.search?.includes('hidesidebar');

  const shouldShowSideBar = brandSetting?.shouldShowSideBar;

  return {
    files,
    transcripts,
    shouldShowChapter,
    chapters,
    shouldShowDiscussion,
    shouldShowTranscript,
    sidebarHasContent,
    shouldShowSideBar
  };
};
