import nookies, { setCookie, destroyCookie } from 'nookies';

export const getToken = (ctx, contentId, contentType) => {
  const cookies = nookies.get(ctx);
  if (
    contentId !== cookies.contentToShowId ||
    contentType !== cookies.contentToShowType
  ) {
    destroyCookie(null, 'contentToken');
    destroyCookie(null, 'contentPassword');
    destroyCookie(null, 'contentToShowId');
    destroyCookie(null, 'contentToShowType');
    return null;
  }
  return cookies?.contentToken;
};

export const getPassword = (ctx, contentId, contentType) => {
  const cookies = nookies.get(ctx);
  if (
    contentId !== cookies.contentToShowId ||
    contentType !== cookies.contentToShowType
  ) {
    destroyCookie(null, 'contentToken');
    destroyCookie(null, 'contentPassword');
    destroyCookie(null, 'contentToShowId');
    destroyCookie(null, 'contentToShowType');
    return null;
  }
  return cookies?.contentPassword;
};

export const getHeading = (heading, title) => {
  if (title) {
    return `${heading} "${title}".`;
  }
  return `${heading}.`;
};

export const loadScript = (src, callback) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  script.onload = () => callback();
  document.body.appendChild(script);
};

export const setToken = (token) => {
  setCookie(null, 'contentToken', token, {
    maxAge: 1 * 60 * 60,
    path: '/',
    sameSite: 'None',
    secure: true
  });
};

export const setPassword = (password) => {
  const hashPassword = Buffer.from(password || '').toString('base64');
  setCookie(null, 'contentPassword', hashPassword, {
    maxAge: 1 * 60 * 60,
    path: '/',
    sameSite: 'None',
    secure: true
  });
};
