import { gql } from '@apollo/client';

export const GET_LIVE_DATA = gql`
  query getLiveData($liveId: ID!) {
    live(id: $liveId) {
      id
      status
      title
      description
      liveUrl
      waitingThumb
      startDate
      disableAnalytic
      shouldShowCountdown
      chapters {
        id
      }
      hasDVR
      hasReplay
      replay {
        id
        isEncodingDone
      }
      publisher {
        id
      }
      discussion {
        id
        isEmbed
      }
      pageSetting {
        id
        startsAt
        shouldShowSummary
        shouldShowDiscussion
        shouldShowShareButton
        shouldShowTranscript
        shouldShowChapter
        pageTitle
        videoTitle
        pageText
        pageTheme
        pageLogo
        accentColor
        watermarkEnabled
        watermarkPosition
        watermarkLink
        watermarkImage
        shouldShowSideBar
      }
      embedSetting {
        id
        startsAt
        shouldShowSummary
        shouldShowDiscussion
        shouldShowShareButton
        shouldShowTranscript
        shouldShowChapter
        pageTitle
        videoTitle
        pageText
        pageTheme
        pageLogo
        accentColor
        watermarkEnabled
        watermarkPosition
        watermarkLink
        watermarkImage
        shouldShowSideBar
      }
    }
  }
`;
