import { gql } from '@apollo/client';

export const GET_DISCUSSION_PROPERTIES = gql`
  query getDiscussionProperties($id: ID!, $isAnimateur: Boolean) {
    discussion(discussionId: $id, isAnimateur: $isAnimateur) {
      id
      shouldShowMessages
      shouldAllowLike
      shouldAllowAnswer
      isEmbed
      moderator {
        id
        name
      }
      fields {
        id
        isMandatory
        isDisplayKey
        isActive
        formLabel {
          id
          type
          label
        }
      }
      live {
        id
        title
        pageSetting {
          accentColor
          pageTitle
          pageLogo
          pageTheme
        }
      }
      media {
        id
        title
        pageSetting {
          accentColor
          pageTitle
          pageLogo
          pageTheme
        }
      }
    }
  }
`;

export const GET_DISCUSSION_MESSAGES_PUBLIC = gql`
  query getMessageFeed($id: ID!, $offset: Int, $limit: Int, $cursor: String) {
    messageFeed(id: $id, offset: $offset, limit: $limit, cursor: $cursor) {
      messageFeedId
      messages {
        id
        createdAt
        updatedAt
        value
        likes
        fromModerator
        isVisible
        isArchived
        fieldValues {
          id
          value
          field {
            id
            isDisplayKey
            formLabel {
              label
            }
          }
        }
        answers {
          id
          value
          likes
          createdAt
          updatedAt
          fromModerator
          isVisible
          fieldValues {
            id
            value
            field {
              id
              isDisplayKey
              formLabel {
                label
              }
            }
          }
        }
      }
      cursor
    }
  }
`;

export const GET_DISCUSSION_MESSAGES_ANIMATEUR = gql`
  query getMessageAnimateurFeed($id: ID!) {
    messageAnimateurFeed(id: $id) {
      id
      createdAt
      updatedAt
      value
      likes
      fromModerator
      isVisible
      isArchived
      fieldValues {
        id
        value
        field {
          id
          isDisplayKey
          formLabel {
            label
          }
        }
      }
      answers {
        id
        value
        likes
        createdAt
        updatedAt
        fromModerator
        isVisible
        fieldValues {
          id
          value
          field {
            id
            isDisplayKey
            formLabel {
              label
            }
          }
        }
      }
    }
  }
`;
