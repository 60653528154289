import { useRouter } from 'next/router';
import { getPassword, getToken } from '../functions/confidentiality';
import { getContentParams } from '../functions/contentParams';

function useHeaders() {
  const router = useRouter();
  const { mediaId, liveId, playlistId, discussionId } = router.query;

  const { contentId, contentType } = getContentParams(
    mediaId,
    liveId,
    playlistId,
    discussionId
  );

  const password = getPassword(null, contentId, contentType);
  const token = getToken(null, contentId, contentType);
  let streamfizzToken;

  if (typeof window !== 'undefined') {
    streamfizzToken = new URLSearchParams(window.location.search).get(
      'streamfizzToken'
    );
  }

  return {
    ...(password && { 'authorization-basic': password }),
    ...(token && { 'auth0-token-pass': token }),
    ...(streamfizzToken && { Authorization: 'Bearer ' + streamfizzToken })
  };
}

export default useHeaders;
