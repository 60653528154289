import { gql } from '@apollo/client'; // eslint-disable-line import/no-extraneous-dependencies

export const GET_MEDIA_DATA = gql`
  query getMediaData($mediaId: ID!) {
    media(id: $mediaId) {
      id
      title
      selectedThumb
      sourceUrl
      isEncodingDone
      type
      playlist
      isPublished
      disableAnalytic
      subtitles {
        id
        languageCode
        url
      }
      discussion {
        id
        isEmbed
      }
      publisher {
        id
        cdnEndpoint
      }
      live {
        id
        waitingThumb
        liveUrl
        hasReplay
      }
      chapters {
        id
      }
      files {
        id
        url
        isActive
        type
      }
      publisher {
        id
        preferences {
          id
          watermarkEnabled
          watermarkPosition
          watermarkLink
          watermarkImage
        }
      }
      pageSetting {
        id
        startsAt
        shouldShowSummary
        shouldShowDiscussion
        shouldShowShareButton
        shouldShowTranscript
        shouldShowChapter
        pageTitle
        videoTitle
        pageText
        pageTheme
        pageLogo
        accentColor
        watermarkEnabled
        watermarkPosition
        watermarkLink
        watermarkImage
        shouldShowSideBar
      }
      embedSetting {
        id
        startsAt
        shouldShowSummary
        shouldShowDiscussion
        shouldShowShareButton
        shouldShowTranscript
        shouldShowChapter
        pageTitle
        pageText
        pageTheme
        pageLogo
        accentColor
        watermarkEnabled
        watermarkPosition
        watermarkLink
        watermarkImage
        shouldShowSideBar
      }
    }
  }
`;
